"use client";

import { EnErrors } from "../../../utils/translations";
import styles from "./not-found.module.scss";

export default function Error() {
  const errorMessages = EnErrors;

  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>{errorMessages.unfortunately_error}</h1>
        <p className={styles.description}>{errorMessages.anyway_explore_website}</p>
        <a href={"/"} className={styles.button}>
          {errorMessages.go_to_homepage}
        </a>
      </div>
      <img className={styles.image} src={"/images/out-of-stock-left.png"} alt="Error" />
    </section>
  );
}
